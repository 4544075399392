import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../sections/Hero'
// import Faq from '../sections/Faq'
import Footer from '../components/Footer'

function Home() {
  return (
    <>
    <Navbar/>
    <Hero/>
    {/* <Faq/> */}
    <Footer/>
    </>
  )
}

export default Home