import { useEffect, useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Success from "../components/Popups/Success";
import axios from "axios";
import BlackFancyPaper from "../components/BlackFancyPaper";

function Hero() {
    const [isModal, setIsModal] = useState(false);
const [captchaVerified, setCaptchaVerified] = useState(false);
const [apiMessage, setApiMessage] = useState('');
    const [token, setToken] = useState(null);
    const captchaRef = useRef(null);
    const inputRef = useRef();
  
 const onLoad = () => {
  captchaRef.current.execute();
};
  
    useEffect(() => {
  
      if (token)
        console.log(`hCaptcha Token: ${token}`);
  
    }, [token]);
    const closePOp =(e) =>{

      e.preventDefault();
      const tweetUrl = inputRef?.current.value;
      if (tweetUrl) {
        let data = JSON.stringify({
                "address": tweetUrl
              });
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://faucet-api.deelance.com/api/claim',
          withCredentials: false,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          console.log("Response Data:", JSON.stringify(response.data));
          if(response.data.msg) {
              console.log("Message from API:", response.data.msg);
               // Remove the 'Txhash:' prefix
      const modifiedMessage = response.data.msg.replace("Txhash: ", "");
      
      setApiMessage(modifiedMessage);  // Store the modified message in the state
    }
  })
  .catch((error) => {
    console.log(error);
  });
      //   const tweetId = tweetUrl.split("/")[5].split("?")[0];
      //   // Rest of your code that depends on tweetId...
      //   axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';   
      //   axios.get(`https://validate-tweet.deelance.com/tweets/${tweetId}`).then(function (response) {
      //     const memeBlockAddr = response.data;
      //     let data = JSON.stringify({
      //       "address": memeBlockAddr
      //     });
          
      //     let config = {
      //       method: 'post',
      //       maxBodyLength: Infinity,
      //       url: 'https://faucet-api.deelance.com/api/claim',
      //       withCredentials: false,
      //       headers: { 
      //         'Content-Type': 'application/json'
      //       },
      //       data : data
      //     };
          
        //   axios.request(config)
        //   .then((response) => {
        //     console.log("Response Data:", JSON.stringify(response.data));
        //     if(response.data.msg) {
        //         console.log("Message from API:", response.data.msg);
        //          // Remove the 'Txhash:' prefix
        // const modifiedMessage = response.data.msg.replace("Txhash: ", "");
        
        // setApiMessage(modifiedMessage);  // Store the modified message in the state
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
          
      //     setIsModal(true);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     setIsModal(false);
      //   });
      } else {
        alert("Tweet URL is not available.");
      }
 
       
    };

    const onVerifyCaptcha = (token) => {
      setToken(token);
      setCaptchaVerified(true);
    };
    const copyToClipboard = () => {
      // Create a text area element
      const el = document.createElement('textarea');
      el.value = apiMessage;  // Set its value to the apiMessage
      document.body.appendChild(el);  // Append it to the body
      el.select();  // Select the content
      document.execCommand('copy');  // Copy the selected content
      document.body.removeChild(el);  // Remove the element from the body
      alert('Message copied to clipboard!');  // Optional: Alert the user that the content has been copied
  }

  return (
    <>
    <section className="pt-12  relative">
    <img src="images/2.png" alt="" className=" hidden sm:block absolute left-0 h-[370px]  top-40" />
      <img src="images/2.png" alt="" className=" hidden sm:block absolute right-0 h-[370px] top-44" />
      <div className="container-wrapper">
        
        <div className="text-center">
          <h1 className=" text-2xl sm:text-4xl font-[600] mb-4 text-[#ffffff]">
            Deelance FAUCET
          </h1>
          <p className="mb-4 text-white">
          Testnet $Dee covers transactions for testing purposes
          </p>
        </div>
        <BlackFancyPaper>
          <img src="images/hero-min.png" alt="" className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-0 scale-[-1.4] scale-y-[-1.4] opacity-20 " />
        <div className="rounded-[20px] card-box max-w-3xl mx-auto relative z-20">
          <div className=" px-10 py-12">
            <form action="" className="flex flex-col">
              <input
                type="text"
                className="input-box px-8 border-2 border-main-green rounded-lg h-full relative"
                placeholder="Paste Your Wallet Address here..."
                ref={inputRef}
              />
              <div className="mx-auto my-2"> 
                 <HCaptcha sitekey="46d18b5d-29d0-48ea-bc7f-806a356135f4"
                onLoad={onLoad}
                onVerify={onVerifyCaptcha}
                ref={captchaRef} />

                <p className="text-sm font-semibold text-white">complete verification first before requesting</p>
              </div>

              <button type="submit" onClick={closePOp} className={captchaVerified ?"bg-[#3f51b8] rounded-xl border-0 mx-auto px-11 py-2 mt-5 text-white capitalize font-medium  " : 'bg-[#5f5f5f] rounded-xl border-0 mx-auto px-11 py-2 mt-5 text-white capitalize font-medium'}
              
                disabled={!captchaVerified}
              >
                Request
              </button>
            </form>
            <div className=" mt-7 mb-6">
              <div className="   border-2 border-main-green rounded-lg">
                <div className="flex justify-between gradent px-6">
                  <h1 className="text-[#fff] text-lg font-semibold">Your Transactions </h1>
                 

                </div>
                <div>
                  <div className="flex justify-between px-6  my-3">
                  {apiMessage && <p className="text-white mb-4 flex justify-between gap-8 items-center ">{apiMessage}    <p className="text-white"><button onClick={copyToClipboard } className="copt-btn">copy</button> </p></p>}
                   
                 

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        </BlackFancyPaper>
      </div>
    </section>
   
    {isModal && <Success setIsModal={setIsModal }/>}
    </>
  );
}

export default Hero;
