import SocialLinks from "./SocialLinks";




function Footer() {

  
  async function addNetwork() {
    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
                chainId: '0x6F22E', // The chainId should be in hexadecimal format
                chainName: 'Deelance Testnet',
                nativeCurrency: {
                    name: 'Deelance Testnet',
                    symbol: 'Dee', 
                    decimals: 18
                },
                rpcUrls: ['https://testnet-rpc.deelance.com/'],
                blockExplorerUrls: ['https://testnet.deelance.com/'],
                // iconUrls: ... (optional)
            }],
        });
    } catch (addError) {
        console.error(addError);
    }
}

  return (
    <footer className="bg-main-bg relative z-20 py-14">
      <div className="container-wrapper">
        <div className="mb-10 relative">
          <div className="static xl:absolute left-0 top-1/2 xl:-translate-y-1/2 flex flex-col xl:flex-row space-y-6 xl:space-y-0 items-center justify-between w-full mb-10 xl:mb-0">
            <img
              src="/images/logo.svg"
              className="w-[150px]"
              alt="deelance-logo"
            />

            <div className="flex items-center space-x-3 justify-end ">
              <SocialLinks />
            </div>
          </div>

          <div className="flex justify-center">
            <div className="flex items-center justify-center relative -my-2 sm:-my-5 -mx-3 sm:-mx-5 flex-wrap">
                 <div className="text-[90%]">
                <button
                  onClick={addNetwork}
                  className="text-[1em] w-fit flex mx-auto mt-[1.4em] "
                >
                  <span className="flex items-center space-x-[.5em] text-white hover:text-green">
                  
                    <img src="/images/metamask.svg" alt="" className="h-[30px]" />
                    <span>{"Add DEE Testnet"}</span>
                  </span>
                </button>
              </div>
          

             
            </div>
          </div>
        </div>

        <p className="text-sm text-grey2 font-plus max-w-[46rem] mx-auto text-center">
          {
            "Disclaimer: Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may fluctuate. Profits may be subject to capital gains or other taxes applicable in your jurisdiction."
          }
        </p>

        <div className="h-[1px] w-full bg-white opacity-20 mt-6 mb-10"></div>

        <p className="text-center text-grey2 text-sm lh-1_6">
          {"2023© DeeLance labs LTD | All Rights Reserved"} <br />
          {
            "Ajeltake Road , Reg no: 119495 , Marshall Islands, info@deelance.com"
          }
        </p>
      </div>
    </footer>
  );
}

export default Footer;
